<template>
  <div>
    <Header></Header>
    <div v-if="error_list">
      <div v-for="(err, err_idx) in error_list" :key="err_idx">
        {{ err }}
      </div>
    </div>
    <div v-else>
      <div id="milestone-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link
                variant="link"
                :to="{ path: `/${lang_info.lang_code}/home` }"
                class=""
                ><i class="arrow right"></i> {{lang_info.lang_code == 'en' ? 'Homepage' : 'Página de inicio'}}
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{
                `${user_milestone_data.grade_key}th Grade ${user_milestone_data.pathway_title} `
              }}
              Action Plan
            </li>
          </ol>
        </nav>
        <milestone> </milestone>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Milestone from "../components/Milestone.vue";
import { mapActions, mapState } from "vuex";
import { sessionStorage } from "../_helpers";

export default {
  name: "Milestones",
  data() {
    return {
      grade_pathway: {},
      error_list: null,
      lang_stanza_key: "actionplan",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async mounted() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let userSessionProfile = sessionStorage.decodeUserSession();

    let data = {};

    data.oidc_id = userSessionProfile.sub;
    data.grade_key = this.$route.params.grade_key;
    data.pathway_key = this.$route.params.pathway_key;

    // TODO refactor API method, obsolete for now.
    // await this.get_grade_pathway_info(data);
    // this.grade_pathway = this.grade_pathway_info;

    data.grade_key_filter_list = [];
    data.pathway_key_filter_list = [];
    data.milestone_key_filter_list = [];
    data.strategy_key_filter_list = [];
    data.article_key_filter_list = [];
    data.lang_code = this.lang_info.lang_code;
    this.error_list = await this.get_user_milestones(data);
    this.refresh++;
  },
  components: {
    Milestone,
  },
  methods: {
    ...mapActions("content", ["get_user_milestones", "get_grade_pathway_info"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
  },
  computed: {
    ...mapState("content", ["grade_pathway_info", "user_milestone_data"]),
  },
};
</script>

<style lang="scss" scoped>
// #milestone-container {
//     border: 1px solid gray;
//     border-bottom-left-radius: 10px;
//     border-bottom-right-radius: 10px;
// }

#milestone-content {
  // background-color: #FFF !important;
}

.breadcrumb {
  margin-bottom: 0;
  position: relative;
  // bottom: 52px;
  background: #ffffff;
  padding: 32px 0px 32px 106px;
  max-height: 200px;
  padding: 20px 0 20px 106px;
}

.breadcrumb-item {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #047AC3;
  max-width: 80%;
}

.breadcrumb-item::before {
  display: none;
}

.breadcrumb li:nth-child(2) {
  display: none;
}

.arrow {
  border: solid #047AC3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.7px;
  border-radius: 1.7px;
  margin-right: 10px;
  margin-left: 11px;
}

.right {
  transform: rotate(0deg);
  -webkit-transform: rotate(135deg);
}

.breadcrumb-item > a {
  color: #1a6edb !important;
}

#milestone-header {
  font-weight: bold;
  font-size: 36px;
  text-align: left;
  padding: 5px 5px 5px 30px;
  color: #fff;
  background: rgb(85, 85, 194);
}

#milestone-subheader {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  padding: 5px 5px 5px 30px;
  background: rgb(203, 230, 255);
}

#milestone-body {
  max-width: 100%;
  padding-right: 5rem;
}

@media (max-width: 499px) {
  .breadcrumb-item > a {
    margin-left: -95px;
  }

  .breadcrumb {
    margin-top: 0px;
  }
}

@media (min-width: 500px) and (max-width: 991px) {
  .breadcrumb {
    padding: 20px 0 20px 20px;
  }
}

</style>