<template>
  <div class="milestone-page-content" v-if="user_milestone_data">
    <div id="background-images">
      <img src="../assets/orange_blob.png" />
      <img src="../assets/green_blob.png" />
      <img src="../assets/orange_blob.png" />
      <img src="../assets/green_blob.png" />
      <img src="../assets/orange_blob.png" />
      <img src="../assets/green_blob.png" />
      <img src="../assets/orange_blob.png" />
    </div>
    <div id="flex-area">
      <div id="menu-area-desktop">
        <div id="menu">
          <div id="menu-title">Steps in this action plan:</div><!-- Needs to be translated --->
          <div
            class="ap-title"
            v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
            :key="ms_idx"
          >
            <span class="menu-date" v-if="ms_idx == 0">August - December</span>
            <div
              v-if="
                milestone.milestone_due_date == 'August-December' ||
                milestone.milestone_due_date == 2
              "
            >
              <div class="ap-nav">
                <input
                  :class="'checkbox-' + milestone.milestone_key"
                  type="checkbox"
                  :checked="milestone.is_user_milestone_complete"
                  @click="
                    saveUserMilestoneProgress(
                      milestone.milestone_key,
                      user_milestone_data.grade_key,
                      user_milestone_data.pathway_key,
                      $event
                    )
                  "
                />
                <a v-bind:href="'#' + milestone.milestone_title">{{
                  milestone.milestone_title
                }}</a>
              </div>
            </div>
          </div>
          <div
            class="ap-title"
            v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
            :key="ms_idx"
          >
            <span class="menu-date" v-if="ms_idx == 0">January - June</span>
            <div
              v-if="
                milestone.milestone_due_date == 'January-June' ||
                milestone.milestone_due_date == 1
              "
            >
              <div class="ap-nav">
                <input
                  :class="'checkbox-' + milestone.milestone_key"
                  type="checkbox"
                  :checked="milestone.is_user_milestone_complete"
                  @click="
                    saveUserMilestoneProgress(
                      milestone.milestone_key,
                      user_milestone_data.grade_key,
                      user_milestone_data.pathway_key,
                      $event
                    )
                  "
                />
                <a v-bind:href="'#' + milestone.milestone_title">{{
                  milestone.milestone_title
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <div class="milestone-page-header">
          <div class="grade-flag">
            <p :class="`${user_milestone_data.grade_text_suffix}`">
              {{ user_milestone_data.grade_title }}
            </p>
          </div>
          <h1 class="category-header">
            {{ user_milestone_data.pathway_title }}
          </h1>
          <p class="milestone-instructions">
            {{ lang_stanza.follow_steps }}
          </p>
          <!-- <img
            class="milestone-img"
            src="../assets/milestone_image.svg"
            alt="Action plan image"
          />
          <p class="steps-completed">
            {{ running_milestones_complete }} /
            {{ user_milestone_data.total_milestones }}
            {{ lang_stanza.steps_completed }}
          </p> -->
        </div>
        <div id="menu-area-mobile">
          <div id="menu">
            <div id="menu-title">Steps in this action plan:</div><!-- Needs to be translated --->
            <div
              class="ap-title"
              v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
              :key="ms_idx"
            >
              <span class="menu-date" v-if="ms_idx == 0"
                >August - December</span
              >
              <div
                v-if="
                  milestone.milestone_due_date == 'August-December' ||
                  milestone.milestone_due_date == 2
                "
              >
                <div class="ap-nav">
                  <input
                    :class="'checkbox-' + milestone.milestone_key"
                    type="checkbox"
                    :checked="milestone.is_user_milestone_complete"
                    @click="
                      saveUserMilestoneProgress(
                        milestone.milestone_key,
                        user_milestone_data.grade_key,
                        user_milestone_data.pathway_key,
                        $event
                      )
                    "
                  />
                  <a v-bind:href="'#' + milestone.milestone_title">{{
                    milestone.milestone_title
                  }}</a>
                </div>
              </div>
            </div>
            <div
              class="ap-title"
              v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
              :key="ms_idx"
            >
              <span class="menu-date" v-if="ms_idx == 0">January - June</span>
              <div
                v-if="
                  milestone.milestone_due_date == 'January-June' ||
                  milestone.milestone_due_date == 1
                "
              >
                <div class="ap-nav">
                  <input
                    :class="'checkbox-' + milestone.milestone_key"
                    type="checkbox"
                    :checked="milestone.is_user_milestone_complete"
                    @click="
                      saveUserMilestoneProgress(
                        milestone.milestone_key,
                        user_milestone_data.grade_key,
                        user_milestone_data.pathway_key,
                        $event
                      )
                    "
                  />
                  <a v-bind:href="'#' + milestone.milestone_title">{{
                    milestone.milestone_title
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="milestone-container"
          v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
          :key="ms_idx"
        >
          <div class="milestone-header" v-bind:id="milestone.milestone_title">
            <div class="step-number">
              <span class="step">{{ lang_stanza.step }}</span>
              {{ milestone.milestone_order_index[0] == 0 ? milestone.milestone_order_index[1] : milestone.milestone_order_index }}
            </div>
            <div class="done-area">
              <p class="p-done">{{ lang_stanza.done }}</p>
              <span v-if="milestone.is_user_milestone_complete === true">
                <input
                  CHECKED
                  @click="
                    saveUserMilestoneProgress(
                      milestone.milestone_key,
                      user_milestone_data.grade_key,
                      user_milestone_data.pathway_key,
                      $event
                    )
                  "
                  id="check-done"
                  :class="'checkbox-' + milestone.milestone_key"
                  type="checkbox"
                  aria-label="i'm done checkbox"
                />
              </span>
              <span v-else>
                <input
                  @click="
                    saveUserMilestoneProgress(
                      milestone.milestone_key,
                      user_milestone_data.grade_key,
                      user_milestone_data.pathway_key,
                      $event
                    )
                  "
                  id="check-done"
                  :class="'checkbox-' + milestone.milestone_key"
                  type="checkbox"
                  aria-label="i'm done checkbox"
                />
              </span>
            </div>
          </div>
          <div
            class="date-background-orange"
            v-if="milestone.milestone_due_date == 1"
          >
            <span class="timeframe">{{ "January-June" }}</span>
          </div>
          <div
            class="date-background-orange"
            v-else-if="milestone.milestone_due_date == 'January-June'"
          >
            <span class="timeframe"
              >{{ milestone.milestone_due_date }}</span
            >
          </div>
          <div
            class="date-background-blue"
            v-if="milestone.milestone_due_date == 2"
          >
            <span class="timeframe">{{ "August-December" }}</span>
          </div>
          <div
            class="date-background-blue"
            v-else-if="milestone.milestone_due_date == 'August-December'"
          >
            <span class="timeframe">{{ milestone.milestone_due_date }}</span>
          </div>
          <div class="milestone-content">
            <h1 class="milestone-title">{{ milestone.milestone_title }}</h1>
            <!-- <div id="milestone-description">
            <p>{{ description }}</p>
          </div>
          <div
            id="milestone-strategies"
            v-for="strategy in strategies"
            :key="strategy"
          >
            <div id="strategy" v-for="subStrategy in strategy" :key="subStrategy">
              {{
                subStrategy.strategy_title.charAt(0).toUpperCase() +
                subStrategy.strategy_title.slice(1)
              }}
            </div>
          </div> -->
            <!-- <div
              v-for="milestone in user_milestone_data.milestone_list"
              :key="milestone.milestone_key"
            > -->
            <!-- <p>
                <br /><strong>grade_key</strong> {{ milestone.grade_key }}
                <br /><strong>pathway_key</strong> {{ milestone.pathway_key }}
                <br /><strong>pathway_title</strong> {{ milestone.pathway_title }}
                <br /><strong>grade_key_suffix</strong>
                {{ milestone.grade_key_suffix }} <br /><strong>grade_text</strong>
                {{ milestone.grade_text }} <br /><strong>grade_text_suffix</strong>
                {{ milestone.grade_text_suffix }} <br /><strong>grade_title</strong>
                {{ milestone.grade_title }} <br /><strong
                  >grade_pathway_title</strong
                >
                {{ milestone.grade_pathway_title }}
              </p> -->

            <!-- <br /><strong>Milestone</strong> <br />milestone_title:
              {{ milestone.milestone_title }} <br />milestone_key:
              {{ milestone.milestone_key }} <br />milestone_sort:
              {{ milestone.milestone_sort }} <br />grade_key: {{ milestone.grade_key }}
              <br />pathway_key: {{ milestone.pathway_key }}
              <p /> -->
            <div class="dynamic-content" v-html="milestone.milestone_body" />
            <div class="reading-activities">{{ "Readings & Activities" }}</div><!-- Needs Translation --->
            <div
              v-for="strategy in milestone.strategy_list"
              :key="strategy.strategy_key"
            >
              <div class="key-topics">
                <span>{{ lang_stanza.key_topics }} :</span>
                <div class="key-topics-text">{{ strategy.strategy_title }}</div>
              </div>
              <!-- <br />----- <br /><strong>Strategy</strong> <br />strategy_title:
                {{ strategy.strategy_title }} <br />strategy_key:
                {{ strategy.strategy_key }} <br />----- --->
              <div
                v-for="article in strategy.article_list"
                :key="article.article_key"
              >
                <div class="article-title">
                  <router-link
                    variant="link"
                    :to="`/${lang_info.lang_code}/article/${article.article_key}`"
                    >{{ article.article_title }}
                  </router-link>
                </div>
                <!-- <div v-if="article.article_content">
                    <br />----- <br /><strong>Article - Content</strong>
                    <br />article_title: {{ article.article_title }}
                    <br />article_key: {{ article.article_key }} <br />article_h_image:
                    {{ article.article_content.article_h_image }} <br />time_estimate:
                    {{ article.article_content.time_estimate }}
                    <br />article_banner_title:
                    {{
                      article.article_content.article_banner
                        .article_banner_title
                    }}
                    <br />article_banner_subtitle:
                    {{
                      article.article_content.article_banner
                        .article_banner_subtitle
                    }}
                    <p />
                    <br />article_body:
                    <div
                      class="dynamic-content"
                      v-html="article.article_content.article_body"
                    />
                    <p />
                    References:
                    <br />
                    <div
                      v-for="(article_reference, idx) in article.article_content
                        .article_references.article_reference_list"
                      :key="idx"
                    >
                      <br />article_reference_header:
                      {{ article_reference.article_reference_header }}
                      <br />article_reference_url:
                      {{ article_reference.article_reference_url }}
                      <br />article_reference_url_text:
                      {{ article_reference.article_reference_url_text }}
                    </div>
                    <p />
                    <br />article_tags: {{ article.article_content.article_tags }}
                    <br />grade_key: {{ article.grade_key }} <br />grade_key_suffix:
                    {{ article.grade_key_suffix }} <br />grade_text:
                    {{ article.grade_text }} <br />grade_text_suffix:
                    {{ article.grade_text_suffix }} <br />grade_title:
                    {{ article.grade_title }} <br />pathway_key:
                    {{ article.pathway_key }} <br />pathway_title:
                    {{ article.pathway_title }} <br />grade_pathway_title:
                    {{ article.grade_pathway_title }} <br />milestone_key:
                    {{ article.milestone_key }} <br />milestone_title:
                    {{ article.milestone_title }} <br />strategy_key:
                    {{ article.strategy_key }} <br />strategy_title:
                    {{ article.strategy_title }}
                  </div>
                  <div v-if="article.article_external">
                    <br />----- <br /><strong>Article - External</strong>
                    <br />article_title: {{ article.article_title }}
                    <br />article_key: {{ article.article_key }}
                    <br />article_h_image: {{ article.article_h_image }}
                    <br />article_description:
                    <div
                      class="dynamic-content"
                      v-html="article.article_external.article_description"
                    />
                    <br />article_external_url:
                    {{ article.article_external.article_external_url }}
                    <br />article_external_url_text:
                    {{ article.article_external.article_external_url }}
                    <br />grade_key: {{ article.grade_key }} <br />grade_key_suffix:
                    {{ article.grade_key_suffix }} <br />grade_text:
                    {{ article.grade_text }} <br />grade_text_suffix:
                    {{ article.grade_text_suffix }} <br />grade_title:
                    {{ article.grade_title }} <br />pathway_key:
                    {{ article.pathway_key }} <br />pathway_title:
                    {{ article.pathway_title }} <br />grade_pathway_title:
                    {{ article.grade_pathway_title }} <br />milestone_key:
                    {{ article.milestone_key }} <br />milestone_title:
                    {{ article.milestone_title }} <br />strategy_key:
                    {{ article.strategy_key }} <br />strategy_title:
                    {{ article.strategy_title }}
                  </div>-->
              </div>
            </div>
            <!-- <div class="disabilities-support">
              <div>Supporting Students With Disabilities</div>
              <br />
              <a href="#">Postsecondary Options for Students with Disabilities</a>
            </div> -->
            <div class="col-2"></div>
          </div>
        </div>
        <div id="button-area">
          <button
            type="button"
            class="scroll-top-btn bg-transparent border-0"
            id="scroll-top-btn"
          >
            <img src="../assets/orange_up_arrow.svg" alt="arrow up button" />
            {{ lang_stanza.back_to_top }}
          </button>
        </div>
      </div>
      <!--
        <div
            id="milestone-container"
            v-for="(milestone, ms_idx) in user_milestone_data.milestone_list"
            :key="ms_idx"
            -->
      <!-- <div class="resources-footer">
          <div>Resources</div>
          <div
              id="resource"
              v-for="(
          article, a_idx
        ) in user_milestone_data.grade_pathway_article_references"
              :key="a_idx"
          >
            <div
                class="resource"
                v-for="(resource, r_idx) in article.article_reference_list"
                :key="r_idx"
            >
              <div class="resource-title">
                {{ resource.article_reference_url_text }}
                <div class="reference-type">
                  <div v-show="resource.article_reference_url_type === 'link'">
                    <a
                        :href="`${resource.article_reference_url}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {{ resource.article_reference_url }}
                    </a>
                  </div>
                  <div
                      class="pdf-reference"
                      v-show="resource.article_reference_url_type === 'pdf'"
                  >
                    <button :href="`${resource.article_reference_url}`">PDF</button>
                  </div>
                </div>
                <p/>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
// import parser from "fast-xml-parser";
import { mapActions, mapState } from "vuex";
import { sessionStorage } from "../_helpers";
import { state } from "../store/content.module";

export default {
  name: "Milestone",
  data() {
    return {
      test: {},
      title: "",
      pathway_title: "",
      description: "",
      strategies: [],
      local_milestones_complete: 0,
      lang_info: {},
      lang_stanza_key: "actionplan",
      lang_stanza: {},
      milestone: {},
    };
  },
  async mounted() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    let userSessionProfile = sessionStorage.decodeUserSession();
    let data = {};
    data.oidc_id = userSessionProfile.sub;
    data.grade_key = this.$route.params.grade_key;
    data.pathway_key = this.$route.params.pathway_key;
    data.lang_code = this.lang_info.lang_code;
    await this.get_user_milestones(data);
    this.pathway_title = `${this.user_milestone_data.pathway_title} Action Plan`;

    this.local_milestones_complete =
      this.user_milestone_data.total_milestones_complete;
    document.body.style.backgroundColor = "#F5F7FA";
    document.title = `${this.$route.params.grade_key}th grade ${this.$route.params.pathway_key} action plan | Tomorrow Ready Texas`;
    window.addEventListener("scroll", this.scrollUp);
    window.scrollTo(0, 0);
  },

  destroyed() {
    window.removeEventListener("scroll", this.scrollUp);
  },
  methods: {
    ...mapActions("content", [
      "get_user_milestones",
      "persistUserMilestoneProgress",
    ]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    scrollUp() {
      let scrollBtn = document.getElementById("scroll-top-btn");
      if (scrollBtn) {
        scrollBtn.addEventListener("click", this.backToTop);
        // if (
        //   document.body.scrollTop > 20 ||
        //   document.documentElement.scrollTop > 20
        // ) {
        //   scrollBtn.style.display = "block";
        // } else {
        //   scrollBtn.style.display = "none";
        //   //push
        // }
      }
    },
    backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    async saveUserMilestoneProgress(
      milestone_key,
      grade_key,
      pathway_key,
      event
    ) {
      if (event.target.checked) {
        this.local_milestones_complete++;
      } else {
        this.local_milestones_complete--;
      }
      let className = "checkbox-" + milestone_key; //form classname of matching checkboxes
      let checkboxes = document.getElementsByClassName(className); // get matching checkboxes (from sidemenu and action plan based on classname)
      let userSessionProfile = sessionStorage.decodeUserSession();
      if (milestone_key && grade_key && pathway_key && userSessionProfile.sub) {
        await this.persistUserMilestoneProgress({
          oidc_id: userSessionProfile.sub,
          grade_key: grade_key,
          pathway_key: pathway_key,
          milestone_key: milestone_key,
          progress_status: event.target.checked,
        });
        Array.from(checkboxes).forEach((element) => {
          //iterate over matching checkboxes in html collection
          element.checked = event.target.checked;
        });
      }
    },
  },
  computed: {
    console: () => console,
    window: () => window,
    ...mapState("content", ["downloads", "user_milestone_data"]),
    ...mapState("content", ["downloads"]),
    prep_pathway_title() {
      return this.user_milestone_data;
    },
    running_milestones_complete() {
      return this.local_milestones_complete;
    },
  },
  // watch: {
  //   '$store.state.user_milestone_data': {
  //     handler() {
  //       return console.log(this.$store.state.user_milestone_data);
  //     },
  //     immediate: true
  //   }
  // }
  // created: {
  //   const mappedState = this.$store.state;
  // }
};
</script>

<style lang="scss" scoped>
.milestone-page-content {
  width: 100%;
  max-height: 90000px;
}

#background-images {
  z-index: -1;
  position: absolute;
  background-color: #FFF;
  max-height: 100% !important;
  max-width: 100%;
  margin-top: -145px; //adjustment for navbar and breadcrumb
  overflow: hidden;

  > img {
    margin-left: 160px;
    max-width: 1188px;
    max-height: 993px !important;
    margin-top: 280px;
  }

  > img:first-of-type {
    margin-top: 375px;
  }
  
}

#flex-area {
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  min-width: 100%;
}

.main-content {
  flex: 1;
  flex-shrink: 2 !important;
  max-width: 1072px;
}

#menu-area-desktop {
  min-width: 480px;
  min-height: 200px;
  margin-bottom: 56px;
}

#menu-area-mobile {
  display: none;
  margin-bottom: 32px;
}

#menu {
  background-color: #FFF;
  border-radius: 14px;
  min-height: 350px;
  width: 280px;
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  // margin-left: 80px;
  margin-left: 96px;
  margin-right: 136px;
  overflow: hidden;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  text-align: left;
  padding: 24px;
}

#menu-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  background-color: #036cae;
  height: 56px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -24px -24px 16px -86px;
}

.menu-date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin: 0 0 16px 0;
}

.ap-nav {
  display: flex;
  // align-items: center;

  input[type="checkbox"] {
    margin-top: 2px;
    height: 20px;
    width: 20px;
  }
  > * {
    font-weight: 700;
    color: #047ac3 !important;
    margin: 0 0 16px 5px;
    flex: 0 0 auto;
  }
  > a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    max-width: 202px;
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

input[type="checkbox"] {
  accent-color: #1c9f79 !important;
  border-radius: 4px;
}

.menu-date,
.ap-nav {
  font-family: "Open Sans";
}

.milestone-page-header {
  max-width: 840px;
  margin-bottom: 64px;
  margin-right: 80px;
}

.done-area {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 32px;
}

p.p-done {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
  margin-right: 8px;
}

.category-header {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  text-align: left;
  margin: 24px 0;
  // width: 50%;
}

.milestone-instructions {
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #3A3A3A;
  text-align: left;
  margin-top: 12px;
}

.milestone-img {
  position: relative;
  bottom: 182px;
  left: 145px;
  z-index: -1;
}

.steps-completed {
  opacity: 0.9;
  color: #3a3a3a;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  margin-top: -152px;
  margin-bottom: 21px;
}

.milestone-container {
  max-width: 840px;
  margin-right: 96px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  margin-bottom: 64px;
  padding-bottom: 8px;
}

.milestone-container:last-of-type {
  margin-bottom: 275px;
}

.milestone-header {
  height: 52px;
  display: flex;
  align-items: center;
  background-color: #036cae;
  scroll-margin-top: 20px;
  border-radius: 16px 16px 0 0;
}

.step,
.step-number {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.step-number {
  margin-left: 32px;
}

.step {
  margin: 8px;
}

.milestone-header > p {
  float: right;
  position: relative;
  margin: 0 !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #3A3A3A;
  line-height: 22px;
}

input[type="checkbox" i]#check-done {
  position: relative;
  float: right;
  height: 32px;
  width: 32px;
}

.date-background-orange {
  > .timeframe {
    background: #fbe6bf;
  }
}

.date-background-blue {
  > .timeframe {
    background: #9bcff0;
  }
}

.date-background-blue,
.date-background-orange {
  text-align: left;
  padding-top: 7px;
}

.timeframe {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 0px 0px 16px 0px;
  padding: 8px 40px;
  color: #030303;
}

.milestone-content {
  padding-left: 40px;
  text-align: left;
}

.milestone-title {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  padding-right: 80px;
  margin-top: 36px;
  margin-bottom: 8px;
}

.dynamic-content {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-right: 80px;
}

.reading-activities {
  margin: 24px 0;
  padding-right: 80px;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
  mix-blend-mode: normal;
  opacity: 0.78;
}

.key-topics > span {
  display: none;
  color: #274579;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.key-topics {
  display: none;
  color: #666666;
  font-family: Lora;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 8px;
  margin-bottom: 32px;
  margin-right: 135px;
  padding: 4px 0px 4px 9px;
  border-radius: 4px;
  background-color: #f5f7fa;
}

.article-title {
  opacity: 0.78;
  color: #030303;
  font-family: 'Open Sans';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: underline;
  margin-bottom: 24px;
  padding-right: 80px;
}

.article-title > a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #3a3a3a;
  mix-blend-mode: normal;
  opacity: 0.78;
}

.article-title > a:hover {
  color: #1a6edb;
  opacity: 1;
  cursor: pointer;
}

#strategy {
  padding-bottom: 20px;
  text-decoration: underline;
}

.grade-flag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  margin: 0;
}

.grade-flag p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 120px;
  border-radius: 16px 0px;
  padding: 10px 0;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}

.disabilities-support > div {
  opacity: 0.9;
  color: rgba(0, 0, 0, 0.85);
  font-family: Lora;
  font-size: 27px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.disabilities-support > a {
  color: #3a3a3a;
  font-family: Lora;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  padding-bottom: 48.5px;
}

.disabilities-support > a:hover {
  color: #1a6edb;
}

.resources-footer {
  margin-left: 313px;
  text-align: left;
  padding-top: 17px;
  margin-bottom: 184px;
}

.resources-footer > div:nth-child(1) {
  color: #070707;
  font-family: Lora;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 11px;
  margin-bottom: 22px;
}

.resource {
  width: 840px;
  border-radius: 9px;
  background-color: #d9ebff;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  padding: 20px 73px 10px 45px;
}

.resource-title {
  color: #030303;
  font-family: Lora;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
}

.pdf-reference {
  margin-bottom: -49px;
}

.reference-type {
  max-width: 720px !important;
}

.reference-type > div > button {
  height: 40px;
  width: 112px;
  border-radius: 16px;
  background-color: #274579;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  position: relative;
  left: 500px;
  bottom: 35px;
}

.reference-type > div > a:hover {
  color: #1a6edb !important;
}

.reference-type > div > a {
  color: #1a6edb !important;
  word-wrap: break-word;
}

.reference-type > div > button:hover {
  background-color: #557eba;
}

#button-area {
  max-width: 840px;
  margin-top: -32px;
  margin-right: 80px;
  display: flex;
  align-content: flex-end;
  align-items: end;
  justify-content: right;
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
}

.scroll-top-btn {
  padding: 0;
  display: flex;
  color: #000;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  align-items: center;
  justify-content: center;
}

.scroll-top-btn > img {
  margin-left: 8px;
  height: 24px;
  width: 24px;
}

.Eighth {
  background-color: #f57f54;
}

.Ninth {
  background-color: #f6c569;
}

.Tenth {
  background-color: #35b888;
}

.Eleventh {
  background-color: #369fe1;
}

.Twelfth {
  background-color: #af709e;
}
</style>

<style scoped>
.dynamic-content >>> h1 {
  color: red;
  font-size: 14pt;
}

.dynamic-content >>> h2 {
  color: purple;
  font-size: 12pt;
}

.dynamic-content >>> h3 {
  color: orange;
  font-size: 11pt;
}

.dynamic-content >>> li {
  color: blue;
  font-size: 10pt;
}

.dynamic-content >>> p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3A3A3A;
}

.dynamic-content >>> td {
  font-size: 10pt;
  background-color: yellow;
  border: 1px solid red;
}

@media (max-width: 1000px) {
  .milestone-title {
    padding-right: 40px;
    text-overflow: ellipsis;
  }

  .dynamic-content {
    padding-right: 40px;
  }

  .article-title {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  #flex-area {
    flex-direction: column;
    background-color: #FFF;
  }

  .milestone-container,
  .milestone-page-header {
    margin-right: 10px;
    margin-left: 10px;
    max-width: none;
  }

  .milestone-container {
    margin-bottom: 32px;
  }

  .milestone-page-header {
    margin-bottom: 12px;
  }

  .milestone-instructions{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3A3A3A;
  }

  #menu-area-desktop {
    display: none;
  }

  #menu-area-mobile {
    display: block;
  }

  #menu {
    width: auto;
    min-width: 355px;
    max-width: 990px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .ap-nav > a {
    max-width: 90%;
  }

  .grade-flag > p {
    height: 40px;
  }

  #menu-title {
    padding: 24px 24px 16px 86px;
    justify-content: left;
  }

  .category-header {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }

  #button-area {
    max-width: none;
    margin-top: 0;
    margin-right: 10px;
  }

  #background-images {
    display: none;
  }
}

@media (max-width: 450px) {
  .milestone-page-header {
    max-width: 375px;
  }

  .milestone-instructions {
    width: 100%;
  }

  #menu-area-mobile {
    width: 375px;
    min-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }

  .ap-nav > a {
    max-width: 280px;
    flex-grow: 1;
  }

  .milestone-title {
    font-size: 24px;
    line-height: 36px;
  }

  .milestone-container {
    max-height: 3000px;
    overflow: hidden;
  }

  #button-area {
    margin-top: 0;
  }
}
</style>