var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user_milestone_data)?_c('div',{staticClass:"milestone-page-content"},[_vm._m(0),_c('div',{attrs:{"id":"flex-area"}},[_c('div',{attrs:{"id":"menu-area-desktop"}},[_c('div',{attrs:{"id":"menu"}},[_c('div',{attrs:{"id":"menu-title"}},[_vm._v("Steps in this action plan:")]),_vm._l((_vm.user_milestone_data.milestone_list),function(milestone,ms_idx){return _c('div',{key:ms_idx,staticClass:"ap-title"},[(ms_idx == 0)?_c('span',{staticClass:"menu-date"},[_vm._v("August - December")]):_vm._e(),(
              milestone.milestone_due_date == 'August-December' ||
              milestone.milestone_due_date == 2
            )?_c('div',[_c('div',{staticClass:"ap-nav"},[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"type":"checkbox"},domProps:{"checked":milestone.is_user_milestone_complete},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                    milestone.milestone_key,
                    _vm.user_milestone_data.grade_key,
                    _vm.user_milestone_data.pathway_key,
                    $event
                  )}}}),_c('a',{attrs:{"href":'#' + milestone.milestone_title}},[_vm._v(_vm._s(milestone.milestone_title))])])]):_vm._e()])}),_vm._l((_vm.user_milestone_data.milestone_list),function(milestone,ms_idx){return _c('div',{key:ms_idx,staticClass:"ap-title"},[(ms_idx == 0)?_c('span',{staticClass:"menu-date"},[_vm._v("January - June")]):_vm._e(),(
              milestone.milestone_due_date == 'January-June' ||
              milestone.milestone_due_date == 1
            )?_c('div',[_c('div',{staticClass:"ap-nav"},[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"type":"checkbox"},domProps:{"checked":milestone.is_user_milestone_complete},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                    milestone.milestone_key,
                    _vm.user_milestone_data.grade_key,
                    _vm.user_milestone_data.pathway_key,
                    $event
                  )}}}),_c('a',{attrs:{"href":'#' + milestone.milestone_title}},[_vm._v(_vm._s(milestone.milestone_title))])])]):_vm._e()])})],2)]),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"milestone-page-header"},[_c('div',{staticClass:"grade-flag"},[_c('p',{class:`${_vm.user_milestone_data.grade_text_suffix}`},[_vm._v(" "+_vm._s(_vm.user_milestone_data.grade_title)+" ")])]),_c('h1',{staticClass:"category-header"},[_vm._v(" "+_vm._s(_vm.user_milestone_data.pathway_title)+" ")]),_c('p',{staticClass:"milestone-instructions"},[_vm._v(" "+_vm._s(_vm.lang_stanza.follow_steps)+" ")])]),_c('div',{attrs:{"id":"menu-area-mobile"}},[_c('div',{attrs:{"id":"menu"}},[_c('div',{attrs:{"id":"menu-title"}},[_vm._v("Steps in this action plan:")]),_vm._l((_vm.user_milestone_data.milestone_list),function(milestone,ms_idx){return _c('div',{key:ms_idx,staticClass:"ap-title"},[(ms_idx == 0)?_c('span',{staticClass:"menu-date"},[_vm._v("August - December")]):_vm._e(),(
                milestone.milestone_due_date == 'August-December' ||
                milestone.milestone_due_date == 2
              )?_c('div',[_c('div',{staticClass:"ap-nav"},[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"type":"checkbox"},domProps:{"checked":milestone.is_user_milestone_complete},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                      milestone.milestone_key,
                      _vm.user_milestone_data.grade_key,
                      _vm.user_milestone_data.pathway_key,
                      $event
                    )}}}),_c('a',{attrs:{"href":'#' + milestone.milestone_title}},[_vm._v(_vm._s(milestone.milestone_title))])])]):_vm._e()])}),_vm._l((_vm.user_milestone_data.milestone_list),function(milestone,ms_idx){return _c('div',{key:ms_idx,staticClass:"ap-title"},[(ms_idx == 0)?_c('span',{staticClass:"menu-date"},[_vm._v("January - June")]):_vm._e(),(
                milestone.milestone_due_date == 'January-June' ||
                milestone.milestone_due_date == 1
              )?_c('div',[_c('div',{staticClass:"ap-nav"},[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"type":"checkbox"},domProps:{"checked":milestone.is_user_milestone_complete},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                      milestone.milestone_key,
                      _vm.user_milestone_data.grade_key,
                      _vm.user_milestone_data.pathway_key,
                      $event
                    )}}}),_c('a',{attrs:{"href":'#' + milestone.milestone_title}},[_vm._v(_vm._s(milestone.milestone_title))])])]):_vm._e()])})],2)]),_vm._l((_vm.user_milestone_data.milestone_list),function(milestone,ms_idx){return _c('div',{key:ms_idx,staticClass:"milestone-container"},[_c('div',{staticClass:"milestone-header",attrs:{"id":milestone.milestone_title}},[_c('div',{staticClass:"step-number"},[_c('span',{staticClass:"step"},[_vm._v(_vm._s(_vm.lang_stanza.step))]),_vm._v(" "+_vm._s(milestone.milestone_order_index[0] == 0 ? milestone.milestone_order_index[1] : milestone.milestone_order_index)+" ")]),_c('div',{staticClass:"done-area"},[_c('p',{staticClass:"p-done"},[_vm._v(_vm._s(_vm.lang_stanza.done))]),(milestone.is_user_milestone_complete === true)?_c('span',[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"CHECKED":"","id":"check-done","type":"checkbox","aria-label":"i'm done checkbox"},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                    milestone.milestone_key,
                    _vm.user_milestone_data.grade_key,
                    _vm.user_milestone_data.pathway_key,
                    $event
                  )}}})]):_c('span',[_c('input',{class:'checkbox-' + milestone.milestone_key,attrs:{"id":"check-done","type":"checkbox","aria-label":"i'm done checkbox"},on:{"click":function($event){return _vm.saveUserMilestoneProgress(
                    milestone.milestone_key,
                    _vm.user_milestone_data.grade_key,
                    _vm.user_milestone_data.pathway_key,
                    $event
                  )}}})])])]),(milestone.milestone_due_date == 1)?_c('div',{staticClass:"date-background-orange"},[_c('span',{staticClass:"timeframe"},[_vm._v(_vm._s("January-June"))])]):(milestone.milestone_due_date == 'January-June')?_c('div',{staticClass:"date-background-orange"},[_c('span',{staticClass:"timeframe"},[_vm._v(_vm._s(milestone.milestone_due_date))])]):_vm._e(),(milestone.milestone_due_date == 2)?_c('div',{staticClass:"date-background-blue"},[_c('span',{staticClass:"timeframe"},[_vm._v(_vm._s("August-December"))])]):(milestone.milestone_due_date == 'August-December')?_c('div',{staticClass:"date-background-blue"},[_c('span',{staticClass:"timeframe"},[_vm._v(_vm._s(milestone.milestone_due_date))])]):_vm._e(),_c('div',{staticClass:"milestone-content"},[_c('h1',{staticClass:"milestone-title"},[_vm._v(_vm._s(milestone.milestone_title))]),_c('div',{staticClass:"dynamic-content",domProps:{"innerHTML":_vm._s(milestone.milestone_body)}}),_c('div',{staticClass:"reading-activities"},[_vm._v(_vm._s("Readings & Activities"))]),_vm._l((milestone.strategy_list),function(strategy){return _c('div',{key:strategy.strategy_key},[_c('div',{staticClass:"key-topics"},[_c('span',[_vm._v(_vm._s(_vm.lang_stanza.key_topics)+" :")]),_c('div',{staticClass:"key-topics-text"},[_vm._v(_vm._s(strategy.strategy_title))])]),_vm._l((strategy.article_list),function(article){return _c('div',{key:article.article_key},[_c('div',{staticClass:"article-title"},[_c('router-link',{attrs:{"variant":"link","to":`/${_vm.lang_info.lang_code}/article/${article.article_key}`}},[_vm._v(_vm._s(article.article_title)+" ")])],1)])})],2)}),_c('div',{staticClass:"col-2"})],2)])}),_c('div',{attrs:{"id":"button-area"}},[_c('button',{staticClass:"scroll-top-btn bg-transparent border-0",attrs:{"type":"button","id":"scroll-top-btn"}},[_c('img',{attrs:{"src":require("../assets/orange_up_arrow.svg"),"alt":"arrow up button"}}),_vm._v(" "+_vm._s(_vm.lang_stanza.back_to_top)+" ")])])],2)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"background-images"}},[_c('img',{attrs:{"src":require("../assets/orange_blob.png")}}),_c('img',{attrs:{"src":require("../assets/green_blob.png")}}),_c('img',{attrs:{"src":require("../assets/orange_blob.png")}}),_c('img',{attrs:{"src":require("../assets/green_blob.png")}}),_c('img',{attrs:{"src":require("../assets/orange_blob.png")}}),_c('img',{attrs:{"src":require("../assets/green_blob.png")}}),_c('img',{attrs:{"src":require("../assets/orange_blob.png")}})])
}]

export { render, staticRenderFns }